<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogSuggestOrderQuestion"
        persistent
        max-width="750px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline"
                >Gợi ý sắp xếp câu hỏi cho bài tập: {{ exercise_title }}</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSuggestOrderQuestion = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="p-0">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(question, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h4
                          class="mt-0 mb-1 font-weight-bold"
                          v-html="question.title"
                        ></h4>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              class="font-weight-bold"
              text
              outlined
              @click="btnSuggestOrderQuestion"
              >Cập nhật</v-btn
            >
            <v-btn
              color="darken-1"
              text
              @click="dialogSuggestOrderQuestion = false"
              >Đóng</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";

export default {
  name: "SuggestOrderQuestionModal",
  components: {
    draggable,
  },
  data() {
    return {
      exercise_title: "",
      data_draggable: [],
    };
  },

  computed: {
    ...mapState({
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogSuggestOrderQuestion: {
      get() {
        return this.$store.state.contentTestBuilderStore
          .dialogSuggestOrderQuestion;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogSuggestOrderQuestion",
          value
        );
      },
    },
  },
  watch: {
    dialogSuggestOrderQuestion: function (val) {
      if (val) {
        this.setDataInput();
      }
    },
  },
  methods: {
    setDataInput() {
      let data =
        this.skills[this.indexSkill].sections[this.indexSection].exercises[
          this.indexExercise
        ];
      this.exercise_title = data.exercise_title;
      this.data_draggable = JSON.parse(JSON.stringify(data.questions));
      this.data_draggable = this.data_draggable.sort(
        (a, b) =>
          parseFloat(a.suggestion_order_index) -
          parseFloat(b.suggestion_order_index)
      );
    },
    btnSuggestOrderQuestion() {
      let vm = this;
      let data_sort = this.data_draggable.map((e, index) => {
        return {
          title: e.title,
          question_id: e.id,
          suggestion_order_index: index,
        };
      });

      Swal.fire({
        title: "Are you sure you want to change?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it!",
      }).then((result) => {
        if (result.value) {
          let data = {
            data_sort: data_sort,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/test/draft/question/suggestion-order", data)
            .then(function (res) {
              if (res.status == 200) {
                vm.actionSuggestOrderQuestion(data_sort);
                vm.is_call_api = false;
                vm.$toasted.success("Thao tác thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.dialogSuggestOrderQuestion = false;
              }
            })
            .catch(function (error) {
              if (error) {
                vm.is_call_api = false;
                vm.$toasted.error(
                  "Something Wrong. Please try again or contact admin !!",
                  {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  }
                );
              }
            });
        }
      });
    },
    actionSuggestOrderQuestion(data) {
      let questions =
        this.skills[this.indexSkill].sections[this.indexSection].exercises[
          this.indexExercise
        ].questions;
      data.forEach((item) => {
        let indexQues = questions.findIndex(
          (question) => question.id === item.question_id
        );
        if (indexQues !== -1) {
          this.skills[this.indexSkill].sections[this.indexSection].exercises[
            this.indexExercise
          ].questions[indexQues].suggestion_order_index =
            item.suggestion_order_index;
        }
      });
      this.$set(this.skills);
    },
  },
};
</script>

<style lang="css" scoped>
.drag-css {
  cursor: pointer;
}
</style>
